import request from '@/utils/request';

//知识库列表
export async function knowledgelist(params = {}) {
  return request.post(`study/knowledge/list`, params);
}
//知识库目录列表
export async function knowledgefolder(params = {}) {
  return request.get(`study/knowledge/folder/tree`, params);
}
//知识库详情
export async function knowledgedetail(params = {}) {
  return request.get(`study/knowledge/${params.id}/detail`, params);
}

