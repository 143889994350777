<template>
  <div class="doc-wrap">
    <!-- <a-spin :spinning="loadingPlayer" size="large" tip="Loading..."> -->
    <div
      id="playArea"
      class="play-area"
      :class="{ dialog: taskIdP > 0, background: showItem == 2 }"
    >
      <template v-if="openViewWatermark">
        <div class="water-marks" v-if="showItem == 3">
          <div class="item" v-for="i in 60" :key="i">
            <OpenData type="userName" :openid="userInfo.realName" />(<OpenData
              type="departmentName"
              :openid="userInfo.departmentName"
            />)
          </div>
        </div>
        <div class="water-mark" v-else>
          <OpenData type="userName" :openid="userInfo.realName" />(<OpenData
            type="departmentName"
            :openid="userInfo.departmentName"
          />)
        </div>
      </template>
      <ali-player
        v-if="showItem == 1 || showItem == 2"
        class="video-player"
        ref="aliplayer"
        width="100%"
        height="100%"
        :autoplay="!isSafari && !isWxWork"
        :useH5Prism="true"
        x5_type="H5"
        :skinLayout="videoPlayer.skinLayout"
        :format="videoPlayer.format"
        controlBarVisibility="click"
        @play="playHandler"
        @pause="pauseHandler"
        @requestFullScreen="fullScreenHandler"
        @cancelFullScreen="cancelFullScreenHandler"
        @ended="onPlayerEnded($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        :source="filePath"
      >
      </ali-player>
      <div id="CCPlay" v-if="showItem == 11"></div>
      <iframe
        id="iframePDF"
        v-if="showItem == 3"
        scrolling="yes"
        width="100%"
        height="100%"
        frameborder="0"
        marginheight="0"
        framespacing="0"
        marginwidth="0"
        :src="filePath"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></iframe>
      <div v-if="showItem == 4" style="overflow: auto; height: 100%">
        <img :src="filePath" style="max-width: 100%" />
      </div>
      <div  v-if="showItem === 5 || showItem === 9"
                :contenteditable="!isAllowcopying"
                v-html="filePath"
                :class="{ unselectable: isAllowcopying === 1 }" 
                style="width: 100%; height: 100%;color: initial;overflow: scroll;"
      ></div>
    </div>
    <!-- </a-spin> -->
    <div class="other-area" :class="{ dialog: taskIdP > 0 }">
      <div class="top">
        <div class="icon">
          <a-tooltip
            placement="leftTop"
            trigger="click"
            :title="dataInfo.intro || $t('Pub_Lab_NoIntro')"
          >
            <ExceptionOutlined />
            <div class="lable">{{ $t("Pub_Lab_Intro") }}</div>
            <!-- 简介 -->
          </a-tooltip>
        </div>
        <div class="icon" @click="clickFavorite">
          <StarOutlined v-if="!dataInfo.isFavorite" />
          <StarFilled style="color: #f9b200" v-else />
          <div class="lable">{{ $t("Pub_Lab_Collect") }}</div>
          <!-- 收藏 -->
        </div>
        <div class="icon" @click="clickLike">
          <LikeOutlined v-if="!dataInfo.isLike" />
          <LikeFilled style="color: #f9b200" v-else />
          <div class="lable">{{ $t("LB_Favorite_SomePraise") }}</div>
          <!-- 点赞 -->
        </div>
        <div class="icon" v-if="hasComment" @click="showCommentCon = true">
          <MessageOutlined />
          <div class="lable">{{ $t("LB_Doc_Comments") }}</div>
          <!-- 评论 -->
        </div>
        <div
          :class="['icon', { loading: downloading }]"
          v-if="dataInfo.isDown == 1"
          @click="clickDownload"
        >
          <DownloadOutlined />
          <div class="lable">
            {{ downloading ? $t("cm_downloading") : $t("cm_download") }}
          </div>
          <!-- "下载中..." : "下载" -->
        </div>
      </div>
      <div class="bottom">
        <div class="countdown" v-if="remainStudyTime > 0">
          <p class="time">{{ convertTimes(remainStudyTime) }}</p>
          <p class="lable">{{ $t("LB_Exam_Countdown") }}</p>
          <!-- 倒计时 -->
        </div>
        <div class="complete" v-else>
          <div>{{ $t("CM_Completed") }}</div>
          <!-- 已完成 -->
        </div>
      </div>
    </div>
  </div>
  <!-- 评论 -->
  <a-modal
    v-model:visible="showCommentCon"
    width="930px"
    :title="$t('LB_Doc_Comments') + '（' + commentParams.Total + '）'"
    :footer="null"
    :z-index="1000"
  >
    <my-comment
      v-if="commentParams.resourceId > 0"
      :CommentParams="commentParams"
    ></my-comment>
  </a-modal>
  <Face
    v-if="dataInfo.openFace == 1 || dataInfo.openFace == 3 || openFace"
    :name="
      taskId > 0 && openFace
        ? `task_${taskId}`
        : courseId > 0
        ? `course_${courseId}`
        : `doc_${id}`
    "
    @confirm="playDetail"
  />
</template>

<script>
import { useI18n } from "vue-i18n";
import VueAliplayer from "@/components/course/vue-aliplayer";
import myComment from "@/components/my-comment";
import { getTaskDetail, taskLearning, taskStartLearn } from "@/api/project";
import {
  getDetail,
  documentLearning,
  knowledgeStartLearn,
  knowledgeLearning,
  like,
  favorite,
  documentStartLearn,
} from "@/api/doc";
import { knowledgedetail } from "@/api/knowledge";
import { wxworkConfig, companyInfo, toDownload } from "@/api/user";
import { platformConfig } from "@/api/other";

import {
  downloadFile,
  fileDownload,
  fullscreen,
  convertTimes,
  isFullscreen,
} from "@/utils/tools";
import { currentHost } from "@/utils/request.js";

import { reactive, ref, toRefs, createVNode, getCurrentInstance } from "vue";
import ls from "@/utils/local-storage";
import { useRouter, useRoute } from "vue-router";
import { onMounted, onUnmounted } from "@vue/runtime-core";
import Face from "@/components/Face.vue";
import OpenData from "@/components/OpenData.vue";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    "ali-player": VueAliplayer,
    "my-comment": myComment,
    Face,
    OpenData,
  },
  props: {
    taskIdP: {
      type: Number,
      default: 0,
    },
    sidP: {
      type: Number,
      default: 0,
    },
    didP: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const { t: $t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const aliplayer = ref(null);
    const ccplayer = ref(null); // 中交云学播放器
    const state = reactive({
      id: parseInt(route.query.id || 0), //课件的id
      courseId: parseInt(route.query.courseId || 0), // 课程id
      sectionId: parseInt(route.query.sectionId || 0), // 课程章节id
      taskId: props.taskIdP || parseInt(route.query.taskId || 0), // 任务id
      sid: props.sidP || parseInt(route.query.sid || 0), // 任务阶段id
      did: props.didP || parseInt(route.query.did || 0), //任务阶段明细的id
      knowledgeId: parseInt(route.query.knowledgeId || 0), //知识库id
      logId: parseInt(route.query.logId || 0), //记录编号
      learnId: parseInt(route.query.learnId || 0), //学习编号
      facePic: "", //人脸采集图片
      openFace: false, //项目包是否配置人脸采集
      type: 0, // 来源类型：0知识 1课程知识 2任务知识 3任务课程知识 4知识库
      documentTimes: 0, // 每页多少秒
      syncData: 2, //是否开启同步学习进度：1开启 2不开启
      dataInfo: {},
      showCommentCon: false,
      commentParams: {
        page: 1,
        pageSize: 10,
        resourceId: 0,
        type: 0, //0知识 1课程 2任务 3新闻
        Total: 0,
      },
      userInfo: ls.get("userInfo"),
      filePath: "",
      showItem: 0,
      loading: false,
      remainStudyTime: 0,
      timer: null,
      currentTime: 0,
      setTimeFlag: 1, // 1从上次播放进度开始播放
      isPause: false, // 是否暂停
      isAllowcopying: 1, // 1:不允许复制  0:允许复制
      videoPlayer: {
        format: "mp4",
        skinLayout: [
          { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
          {
            name: "controlBar",
            align: "blabs",
            x: 0,
            y: 0,
            children: [
              { name: "progress", align: "blabs", x: 0, y: 44 },
              { name: "playButton", align: "tl", x: 15, y: 12 },
              { name: "timeDisplay", align: "tl", x: 10, y: 7 },
              {
                name: "fullScreenButton",
                align: "tr",
                x: 10,
                y: 12,
              },
              { name: "setting", align: "tr", x: 15, y: 12 },
              { name: "volume", align: "tr", x: 5, y: 10 },
            ],
          },
        ],
      },
      loadingPlayer: false,
      showCommentCon: false,
      downloading: false,
      psign: "",
      hasComment: false,
      openViewWatermark: true,
      openDownloadWatermark: false,
    });

    companyInfo().then((res) => {
      if (res.ret == 0) {
        state.hasComment = res.data.useComment == 1;
        state.openViewWatermark = res.data.isOpenViewWatermark == 1;
        state.openDownloadWatermark = res.data.isOpenDownloadWatermark == 1;
      }
    });

    const ua =
      navigator && navigator.userAgent
        ? navigator.userAgent.toLowerCase() || ""
        : "";
    let isWxWork = /wxwork/i.test(ua);
    let isDingding = /dingtalk/i.test(ua);
    let isSafari =
      /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    platformConfig({ site: currentHost }).then((res) => {
      if (res.data.platform == 26) {
        if (isWxWork) {
          console.log("isWxWork");
          wxworkConfig({
            url:
              location.protocol +
              "//" +
              location.hostname +
              location.pathname +
              location.search,
            jsTicketType: "2", // 不为空 - 获取企业标识 为空 则为应用标识
          }).then((r) => {
            let d = r.data;
            wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: d.corpId, // 必填，公众号的唯一标识
              timestamp: d.timestamp, // 必填，生成签名的时间戳
              nonceStr: d.nonceStr, // 必填，生成签名的随机串
              signature: d.signature, // 必填，签名，见附录1
              jsApiList: [
                "checkJsApi",
                "startLiving",
                "replayLiving",
                "agentConfig",
                "invoke",
                "startMeeting",
              ],
              openTagList: ["wx-open-launch-app"],
            });
            wx.ready(() => {
              wxworkConfig({
                url:
                  location.protocol +
                  "//" +
                  location.hostname +
                  location.pathname +
                  location.port +
                  location.search,
              }).then((res) => {
                let d2 = res.data;
                wx.agentConfig({
                  debug: true,
                  corpid: d2.corpId,
                  agentid: d2.agentid,
                  timestamp: d2.timestamp,
                  nonceStr: d2.nonceStr,
                  signature: d2.signature,
                  jsApiList: ["selectExternalContact"],
                  success: function (res) {
                    // 回调
                    console.log("agentConfigSuccess：" + JSON.stringify(res));
                  },
                  fail: function (res) {
                    if (res.errMsg.indexOf("function not exist") > -1) {
                      alert($t("the_version_is_too_low_please_upgrade"));
                      // 版本过低请升级
                    }
                  },
                });
              });
            });
            wx.error((res) => {
              console.log(res, "---");
            });
          });
        } else {
          wxworkConfig({
            url:
              location.protocol +
              "//" +
              location.hostname +
              location.pathname +
              location.port +
              location.search,
          }).then((res) => {
            let d = res.data;
            wx.agentConfig({
              debug: true,
              corpid: d.corpId,
              agentid: d.agentid,
              timestamp: d.timestamp,
              nonceStr: d.nonceStr,
              signature: d.signature,
              jsApiList: ["selectExternalContact"],
              success: function (res) {
                // 回调
                console.log("agentConfigSuccess：" + JSON.stringify(res));
              },
              fail: function (res) {
                if (res.errMsg.indexOf("function not exist") > -1) {
                  alert($t("the_version_is_too_low_please_upgrade"));
                  // 版本过低请升级
                }
              },
            });
          });
        }
      } else if (res.data.platform == 31 && isDingding) {
        let dingCorpid = ls.get("dingCorpid");
        let ddInit = window.DTOpenData.init(dingCorpid);
        console.log("ddInit:" + ddInit);
      }
    });

    const createPlayer = () => {
      ccplayer.value = window.createCCH5Player({
        vid: state.dataInfo.outFileId,
        siteid: "46D5B89ABE2D25D6",
        playtype: 1,
        isShare: false,
        open_barrage: false,
        isShowExercise: false,
        isShowQuestions: false,
        isShowVisitor: false,
        vc: state.psign,
        autoStart: false,
        width: "100%",
        height: "100%",
        parentNode: document.getElementById("CCPlay"),
      });
    };

    const ccPlay = () => {
      ccplayer.value && ccplayer.value.destroy();
      createPlayer();
    };

    //点击下载
    const clickDownload = () => {
      state.downloading = true;
      //下载后文件名
      let fileName = state.dataInfo.fileName + "." + state.dataInfo.suffix;
      if (state.openDownloadWatermark) {
        if (state.dataInfo.fileType == 1 || state.dataInfo.fileType == 3) {
          toDownload({
            fileName: fileName,
            filePath: state.dataInfo.sourceFilePath,
            isGenerateWatermark: true,
            source:
              "【" + $t("XB_KnowledgeBase") + "】" + state.dataInfo.fileName,
            title: state.dataInfo.fileName,
          }).then((res) => {
            state.downloading = false;
            proxy.$message.info($t("downloadCenterTips"));
            // Modal.confirm({
            //   icon: createVNode(ExclamationCircleOutlined),
            //   content: $t("downloadCenterTips"),
            //   okText: $t("CM_Affirm"),
            //   cancelText: $t("CM_Cancel"),
            //   onOk() {
            //     router.push("/mine/down");
            //   },
            // });
          });
        } else {
          fileDownload(state.dataInfo.sourceFilePath, fileName);
          // downloadFile(
          //   "post",
          //   `study/knowledge/down`,
          //   { id: state.knowledgeId },
          //   fileName,
          //   () => {
          //     state.downloading = false;
          //   }
          // );
        }
      } else {
        fileDownload(state.dataInfo.sourceFilePath, fileName);
        downloadFile(
          "post",
          `study/knowledge/down`,
          { id: state.knowledgeId },
          fileName,
          () => {
            state.downloading = false;
          }
        );
      }
    };

    const GetDetail = () => {
      let detail = getDetail;
      let params = { id: state.id };
      if (state.type == 2) {
        detail = getTaskDetail;
        params = { id: state.taskId, did: state.did };
      } else if (state.type == 4) {
        detail = knowledgedetail;
        params = { id: state.knowledgeId };
      }
      detail(params).then((data) => {
        if (data.ret == 0) {
          state.dataInfo = data.data;
          if (state.type == 0) {
            state.dataInfo = data.data;
            state.commentParams.resourceId = state.id;
            state.sectionId = state.id;
          } else if (state.type == 2) {
            state.dataInfo = data.data.document;
            state.openFace =
              data.data.openFace == 1 || data.data.openFace == 3 ? true : false;
            state.courseId = state.dataInfo.documentId;
            state.commentParams.resourceId = state.dataInfo.documentId;
            state.documentTimes = state.dataInfo.documentTimes;
          } else if (state.type == 4) {
            state.dataInfo = data.data;
            state.commentParams.resourceId = state.dataInfo.documentId;
            state.sectionId = state.dataInfo.documentId;
          }
          //是否开启同步学习进度
          if (data.data.syncData) {
            state.syncData = data.data.syncData;
          }
          //计算文档类型的学习总时长
          if (state.dataInfo.fileType == 3 && state.documentTimes > 0) {
            state.dataInfo.times = state.documentTimes * state.dataInfo.pages; // 文档学习总时长 = 每页多少秒 * 总页数
          }
          //显示课件学习倒计时
          state.remainStudyTime = Math.ceil(
            state.dataInfo.times -
              (state.dataInfo.times * state.dataInfo.progress) / 100
          ); // 剩余时长 = 总时长 - 已完成时长
          if (
            state.dataInfo.openFace == 1 ||
            state.dataInfo.openFace == 3 ||
            state.openFace
          ) {
            return false;
          }
          playDetail();
        } else {
          //必须按顺序解锁学习
          if (data.data && data.data.learnOrder) {
            router.replace({
              path: `/project/detail`,
              query: { id: state.taskId },
            });
          } else {
            router.replace({
              path: `/error`,
              query: {
                title: $t("knowledge.no_exist"),
                // 抱歉，您访问的知识不存在。
                path: "/knowledge",
                name: $t("XB_KnowledgeBase"),
                // 知识库
              },
            });
          }
        }
      });
    };

    const receiveMessage = (e) => {
      if (e.data == "pdf-fullscreen") {
        fullscreen(document.querySelector("#playArea"));
      } else if (e.data == "pdf-fullscreen-status") {
        let pdfRef = document.querySelectorAll("#iframePDF")[0].contentWindow;
        let presentationModeButton =
          pdfRef.PDFViewerApplication.appConfig.toolbar.presentationModeButton;
        if (isFullscreen()) {
          presentationModeButton.classList.add("hidden");
        } else {
          presentationModeButton.classList.remove("hidden");
        }
      }
    };

    const playDetail = (data) => {
      if (data) state.facePic = data;
      setTimeout(() => {
        switch (state.dataInfo.fileType) {
          case 0: //外部同步
            if (
              state.dataInfo.courseType == 5 &&
              state.dataInfo.providerType == 1
            ) {
              state.showItem = 11;
              zjyxSing({
                trainId: state.dataInfo.outSourceId,
                trainName: state.dataInfo.outSourceName,
                videoId: state.dataInfo.outFileId,
              }).then((res) => {
                state.psign = res.data.psign;
                if (window.ccH5PlayerJsLoaded) {
                  ccPlay();
                }
              });
            }
            break;
          case 1: //视频
            state.showItem = 1;
            if (isSafari || isWxWork) {
              state.filePath =
                state.dataInfo.filePath.slice(
                  0,
                  state.dataInfo.filePath.lastIndexOf(".")
                ) + "/1/mp4/1_1.mp4";
            } else {
              state.videoPlayer.format = "m3u8";
              state.filePath =
                state.dataInfo.filePath.slice(
                  0,
                  state.dataInfo.filePath.lastIndexOf(".")
                ) + "/1/hls/index.m3u8";
            }
            showPlayer();
            break;
          case 2: //音频
            state.showItem = 2;
            state.videoPlayer.format = "mp3";
            state.filePath = state.dataInfo.filePath;
            showPlayer();
            break;
          case 3: //文档
            state.showItem = 3;
            let pdfUrl =
              state.dataInfo.filePath.split(state.dataInfo.suffix)[0] + "pdf";
            state.filePath =
              "/pdfview/web/viewer.html?v=2&canfull=no&file=" +
              encodeURIComponent(pdfUrl + "?" + state.dataInfo.fileName);
            playHandler();
            delayHideLoading();
            setTimeout(() => {
              console.log(isWxWork, isDingding);
              if (isWxWork || isDingding) {
              } else {
                window.addEventListener("message", receiveMessage, false);
              }
            }, 500);
            break;
          case 4: //图片
            state.showItem = 4;
            state.filePath = state.dataInfo.filePath;
            playHandler();
            delayHideLoading();
            break;
          case 5: //文本
            state.showItem = 5;
            state.filePath =
              "2月19日，元宵节。今年，“十五的月亮十五圆”。根据精确推算，" +
              "19日17时3分月球过近地点的距离只有35.68万千米，是月球全年距离地球最近的一刻，23时54分，月亮呈现当夜“最圆”。只要天气晴好，" +
              "我国公众可欣赏到一轮皎洁明亮的“超级元宵月”！会计师对话框方式";
            playHandler();
            delayHideLoading();
            break;
          case 6: //H5
            state.showItem = 3;
            showUrl();
            break;
          case 10: //Scorm
            state.showItem = 3;
            showUrl();
            break;
          case 8: //Url
            state.showItem = 3;
            showUrl();
            break;
            case 9: //文本
            state.showItem = 5;
            state.isAllowcopying = state.dataInfo.isAllowcopying;
            state.filePath =state.dataInfo.content;
            playHandler();
            delayHideLoading();
            break;
          default:
            break;
        }
      }, 100);
    };

    const showPlayer = () => {
      var el =
        document.getElementsByTagName("video")[0] ||
        document.getElementsByTagName("audio")[0];
      if (el) {
        //重新创建播放器
        aliplayer.value.scriptTagStatus = 2;
        aliplayer.value.initAliplayer();
      }
    };
    const showUrl = () => {
      state.filePath = state.dataInfo.filePath.replace(
        ".zip",
        "/" + state.dataInfo.home
      );
      playHandler();
      delayHideLoading();
    };

    //开始播放时触发
    const playHandler = () => {
      state.isPause = false;
      if (ccplayer.value) {
        //从上次播放的位置开始播放音频
        if (state.setTimeFlag == 1) {
          let getDuration = ccplayer.value.getDuration() || 0; //获取视频总时长
          let cktime = Math.floor(state.dataInfo.location); //获取视频最后播放位置
          // 跳转到某个时刻进行播放
          if (cktime > 0 && cktime != Math.floor(getDuration)) {
            ccplayer.value.jumpToTime(cktime);
          }
          state.setTimeFlag = 2;
        }
        state.dataInfo.location = ccplayer.value.getPosition();
      }
      //没有学习编号，先增加学习编号
      if (state.learnId == 0) {
        startLearn();
      }
      startInterval();
    };
    // 暂停播放时触发
    const pauseHandler = () => {
      state.isPause = true;
    };
    // 当结束播放时触发
    const onPlayerEnded = () => {
      submitLearning();
    };
    // 播放位置发生改变时触发
    const onPlayerTimeupdate = () => {
      if (aliplayer.value == null) {
        return false;
      }
      //从上次播放的位置开始播放音频
      if (state.setTimeFlag == 1) {
        let getDuration = aliplayer.value.getDuration() || 0; //获取视频总时长
        let cktime = Math.floor(state.dataInfo.location); //获取视频最后播放位置
        // 跳转到某个时刻进行播放
        if (cktime > 0 && cktime != Math.floor(getDuration)) {
          aliplayer.value.seek(cktime);
        }
        // 延时 隐藏 loading 效果
        delayHideLoading();
        state.setTimeFlag = 2;
      }
      state.dataInfo.location = aliplayer.value.getCurrentTime();
    };
    const startInterval = () => {
      clearInterval(state.timer);
      state.timer = setInterval(() => {
        if (state.isPause) {
          return false;
        }
        state.remainStudyTime -= 1;
        state.currentTime += 1;
        //每10秒提交一次 || 剩余学习时间 == 0
        // console.log(state.currentTime);
        if (state.currentTime % 10 == 0 || state.remainStudyTime == 0) {
          console.log("提交");
          submitLearning();
        }
      }, 1000);
    };

    const startLearn = () => {
      if (state.dataInfo.fileType == 3 && window[0]) {
        // 获取当前pdf页数
        window.addEventListener(
          "message",
          function (e) {
            state.dataInfo.location = e.data;
          },
          false
        );
      }
      let params = {
        taskId: state.taskId,
        stageId: state.sid,
        detailId: state.did,
        courseId: state.courseId,
        sectionId: state.sectionId,
        location: parseInt(state.dataInfo.location),
        logId: state.logId,
        learnId: state.learnId,
        face: state.facePic,
      };
      let action = documentStartLearn;
      if (state.type == 4) {
        action = knowledgeStartLearn;
        params.knowledgeId = state.knowledgeId;
      }
      if (state.taskId > 0) {
        action = taskStartLearn;
      }
      action(params).then((data) => {
        if (data.ret == 0) {
          state.learnId = data.data.learnId;
          updateDate(data.data);
        }
      });
    };
    const submitLearning = () => {
      if (state.dataInfo.fileType == 3 && window[0]) {
        // 获取当前pdf页数
        window.addEventListener(
          "message",
          function (e) {
            state.dataInfo.location = e.data;
          },
          false
        );
      }
      let params = {
        taskId: state.taskId,
        stageId: state.sid,
        detailId: state.did,
        courseId: state.courseId,
        sectionId: state.sectionId,
        location: parseInt(state.dataInfo.location),
        logId: state.logId,
        learnId: state.learnId,
      };
      let action = documentLearning;
      if (state.type == 4) {
        action = knowledgeLearning;
        params.knowledgeId = state.knowledgeId;
      }
      if (state.taskId > 0) {
        action = taskLearning;
      }
      action(params).then((data) => {
        if (data.ret == 0) {
          updateDate(data.data);
          if (window.opener) {
            let parent = window.opener;
            parent.postMessage("reload-project", location.origin);
          }
        }
      });
    };
    const updateDate = (data) => {
      state.logId = data.logId;
      if (data.complete || state.remainStudyTime <= 0) {
        state.dataInfo.progress = 100;
      }
      //开启同步学习进度时 同步学习位置
      if (state.syncData == 1 && state.setTimeFlag == 1) {
        state.dataInfo.location = data.location;
      }
    };
    //延时隐藏加载效果，默认延迟0.1秒
    const delayHideLoading = (time = 100) => {
      setTimeout(() => {
        state.loadingPlayer = false;
      }, time);
    };

    const clickFavorite = () => {
      favorite({ id: state.dataInfo.documentId }).then((data) => {
        if (data.ret == 0) {
          state.dataInfo.isFavorite = data.data;
        }
      });
    };

    const clickLike = () => {
      like({ id: state.dataInfo.documentId }).then((data) => {
        if (data.ret == 0) {
          state.dataInfo.isLike = data.data;
        }
      });
    };
    onMounted(() => {
      if (state.id > 0) {
        state.type = 0;
      }
      if (state.taskId > 0) {
        state.type = 2;
      }
      if (state.knowledgeId > 0) {
        state.type = 4;
        document.title = $t("XB_KnowledgeDetail");
      }
      GetDetail();
      window.on_CCH5player_pause = pauseHandler;
      window.on_CCH5player_play = playHandler;
      window.on_CCH5player_ended = onPlayerEnded;
    });

    onUnmounted(() => {
      if (state.timer) {
        // 清除定时器
        clearInterval(state.timer);
        state.timer = null;
        submitLearning();
      }
      if (ccplayer.value) {
        ccplayer.value.destroy();
        ccplayer.value = null;
      }
    });

    const fullScreenHandler = () => {
      let water = document.getElementsByClassName("water-mark")[0];
      let wrap = document.getElementsByClassName("prism-player")[0];
      let water2 = water.cloneNode(true);
      wrap.appendChild(water2);
    };

    const cancelFullScreenHandler = () => {};

    return {
      ...toRefs(state),
      convertTimes,
      playHandler,
      pauseHandler,
      aliplayer,
      onPlayerTimeupdate,
      clickFavorite,
      clickLike,
      playDetail,
      clickDownload,
      onPlayerEnded,
      isSafari,
      isWxWork,
      fullScreenHandler,
      cancelFullScreenHandler,
    };
  },
};
</script>

<style lang="less" scoped>
.doc-wrap {
  width: 100%;
  height: 100%;
  color: #ffffff;
  display: flex;
  overflow: hidden;
  .play-area {
    width: calc(100vw - 60px);
    height: 100vh;
    text-align: left;
    padding: 20px;
    position: relative;
    #CCPlay,
    .video-player {
      width: 100%;
      height: 100%;
    }
    &.background .video-player {
      ::v-deep(.prism-player) {
        background: url("../../assets/image/audio-cover.gif") no-repeat;
        background-size: 100% 100%;
      }
    }
    &.dialog {
      width: calc(100% - 60px);
      height: 100%;
    }
  }
  .other-area {
    width: 60px;
    height: 100vh;
    background: #1d1d1d;
    .top {
      height: calc(100vh - 60px);
      .icon {
        width: 60px;
        height: 81px;
        padding: 12px 0;
        text-align: center;
        cursor: pointer;
        &.loading {
          pointer-events: none;
          opacity: 0.5;
        }
        .anticon {
          font-size: 18px;
          padding: 8px;
          border-radius: 20px;
          background: #fefefe30;
        }
        .lable {
          color: #f2f2f2;
          font-size: 12px;
          margin-top: 5px;
        }
      }
    }
    .bottom {
      height: 60px;
      text-align: center;
      .countdown {
        height: 100%;
        padding: 5px 0;
        line-height: 24px;
        background: #cf4545;
        p {
          margin: 0;
        }
        .lable {
          font-size: 12px;
        }
        .time {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .complete {
        height: 100%;
        line-height: 60px;
        background: #22c1ad;
      }
    }
    &.dialog {
      height: 100%;
      .top {
        height: calc(100% - 60px);
      }
    }
  }
}
.water-mark {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 24px;
  color: rgba(170, 170, 170, 0.4);
  line-height: 40px;
}
.water-marks {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 0;
  left: 0;
  top: 34px;
  bottom: 0;
  overflow: hidden;
  .item {
    float: left;
    transform: rotate(-20deg);
    margin: 80px 50px 250px 50px;
    font-size: 18px;
    line-height: 40px;
    color: rgba(170, 170, 170, 0.2);
    pointer-events: none;
  }
}
.unselectable {
  user-select: none; /* 阻止文本选择 */
  -webkit-user-select: none; /* 针对Webkit内核的浏览器 */
  -moz-user-select: none; /* 针对Firefox浏览器 */
  -ms-user-select: none; /* 针对IE和Edge浏览器 */
}
</style>
